import React from 'react';
import {useState, useEffect} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, TextField, Button, Container, Paper, Typography, CircularProgress } from '@mui/material';
import useVoices from '../../hooks/useVoices';
import useGenerateMultipleVoices from '../../hooks/useGenerateMultipleVoices';
import SentenceForm from '../SentenceForm/SentenceForm';
import useSentenceForm from '../../hooks/useSentenceForm';
import AudioSettingsForm from '../AudioSettingsForm/AudioSettingsForm';
import ReactPlayer from 'react-player';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

function AudioForm() {
    const { voices } = useVoices();
    const { generateVoices, audioUrl, loading, error, taskStatus } = useGenerateMultipleVoices(); 
    const { sentences, setSentences, addSentence, removeSentence, updateSentence } = useSentenceForm(voices);
    const [isGenerating, setIsGenerating] = useState(false);
    const [hasGenerated, setHasGenerated] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [audiosettings, setAudioSettings] = useState({
        intro: '',
        outro: '',
        bgaudio: ''
    });

    // Drag and drop
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(sentences);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setSentences(items);
    };

    const handleSettingChange = (setting, value) => {
        setAudioSettings(prevSettings => ({
            ...prevSettings,
            [setting]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsGenerating(true);
        setHasGenerated(false);
        await generateVoices(sentences, audiosettings);
        // Error handling verplaatst naar useEffect
    };

    useEffect(() => {
        if (audioUrl) {
            setIsGenerating(false);
            setHasGenerated(true);
        }
    }, [audioUrl]);

    useEffect(() => {
        if (error) {
            setIsGenerating(false);
            setHasGenerated(false);
            setOpenSnackbar(true); // Open the Snackbar
        }
    }, [error]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (taskStatus === 'Task completed') {
            setIsGenerating(false);
            setHasGenerated(true);
        } else if (taskStatus === 'Task in progress') {
            setIsGenerating(true);
        }
    }, [taskStatus]);

    return (
        <Container className="container">
            <Paper className="paper">
                <box className="typographyHeader">
                    <Typography 
                        variant="h4" 
                    >
                            Ontwerp een Amatourflits
                    </Typography>
                </box>

                <form onSubmit={handleSubmit}>
                
                <TextField
                    id="test-field"
                    label="Testveld voor knippen en plakken"
                    multiline
                    rows={2}
                    className="testField"
                    variant="outlined"
                />
                
                    <AudioSettingsForm 
                        audiosettings={audiosettings} 
                        setAudioSettings={setAudioSettings} 
                        handleSettingChange={handleSettingChange}
                    />

                    <box className="typographyHeader">
                        <Typography variant="h5" className="typographyHeader">
                            Voeg fragmenten toe
                        </Typography>
                    </box>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="dropsentences">
                            {(provided) => (
                                <div 
                                    {...provided.droppableProps} 
                                    ref={provided.innerRef}
                                >
                                    {sentences.map((sentence, index) => (
                                        <Draggable 
                                            key={`sentence-${index}`} 
                                            draggableId={`sentence-${index}`} 
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    className="draggableContainer"
                                                >
                                                    <div {...provided.dragHandleProps} 
                                                        className="dragHandleContainer">
                                                        <DragHandleIcon />
                                                    </div>
                                                   
                                                    <SentenceForm
                                                        index={index}
                                                        sentence={sentence}
                                                        voices={voices}
                                                        onSentenceChange={updateSentence}
                                                        onRemoveSentence={removeSentence}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {audioUrl && (
                        <div className="audioSection">
                            <Box className="typographyHeader">
                                <Typography variant="h5" >
                                    Amatourflits gereed!
                                </Typography>
                            </Box>
                                
                                <ReactPlayer
                                    url={audioUrl}
                                    controls={true}
                                    className="audioPlayer"
                                />
                            
                        </div>
                    )}
                    {error && <div>Error: {error}</div>}

                    <div className="buttonGroup">
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={addSentence} 
                            disabled={loading}
                            className="addButton"
                        >
                            Voeg Fragment Toe
                        </Button>
                        <Box mr={2} />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={loading || isGenerating}
                            className="submitButton"
                        >
                            {loading || isGenerating ? (
                                <>
                                    <CircularProgress size={24} className="addRemoveButton" />
                                    {taskStatus === 'Task in progress' ? 'Amatourflits aan het maken (dit kan een minuutje duren)' : 'Taak wordt verwerkt...'}
                                </>
                            ) : hasGenerated ? 'Genereer nog een Amatourflits' : 'Genereer Amatourflits'}
                        </Button>

                        
                    </div>
                    
                </form>
            </Paper>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    Er is een fout opgetreden: {error}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default AudioForm;
