import { useState, useEffect } from 'react';

const useAudioFiles = (folder) => {
    const [audioFiles, setAudioFiles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //fetch(`https://surrounded-3fe36f6a1847.herokuapp.com/api/media?folder=${folder}`)
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/media?folder=${folder}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => setAudioFiles(data.files))
            .catch(error => console.error('Error loading audio files:', error))
            .finally(() => setLoading(false));
    }, [folder]);

    return { audioFiles, loading };
};

export default useAudioFiles;