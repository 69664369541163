// src/hooks/useGenerateMultipleVoices.js
import { useState, useEffect } from 'react';
import config from '../config';

const useGenerateMultipleVoices = () => {
    const [audioUrl, setAudioUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [taskId, setTaskId] = useState(null);
    const [taskStatus, setTaskStatus] = useState(null);


    const generateVoices = async (textVoicePairs, audioSettings) => {
        setLoading(true);
        setError(null);
        setAudioUrl(null); // Reset the audioUrl
        setTaskStatus('Task in progress'); // Set the task status to 'Task in progress'
        try {
            const introPath = audioSettings.intro ? `${config.SITE_NAME}/intros/${audioSettings.intro}` : '';
            const outroPath = audioSettings.outro ? `${config.SITE_NAME}/outros/${audioSettings.outro}` : '';
            const bgAudioPath = audioSettings.bgaudio ? `${config.SITE_NAME}/bgaudio/${audioSettings.bgaudio}` : '';

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-multiple-voices`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ textVoicePairs, intro: introPath, outro: outroPath, bgaudio: bgAudioPath }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setTaskId(data.task_id);
        } catch (e) {
            setError(e.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let intervalId;
    
        const checkTaskStatus = async () => {
            if (taskId) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-status/${taskId}`);
                    const data = await response.json();
    
                    if (data.status === 'Task completed') {
                        clearInterval(intervalId);
                        if (data.result && data.result.status === 'Error') {
                            setError(data.result.message || 'Er is een fout opgetreden bij het verwerken van de taak');
                            setLoading(false);
                        } else {
                            setAudioUrl(data.result.audio_file);
                            setLoading(false);
                        }
                        setTaskId(null);
                    } else {
                        setTaskStatus(data.status);
                    }
                } catch (error) {
                    clearInterval(intervalId);
                    setError('Fout bij het ophalen van de taakstatus');
                    setLoading(false);
                    setTaskId(null);
                }
            }
        };
    
        if (taskId) {
            intervalId = setInterval(checkTaskStatus, 5000); // Check elke 5 seconden
        }
    
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [taskId]);
    

    return { audioUrl, generateVoices, loading, error, taskStatus };
};

export default useGenerateMultipleVoices;